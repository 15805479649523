


















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import * as d3 from 'd3';
import ScatterChartData from '@/models/scatterChartData';
import Dropdown from '@/components/dropdown.vue';
import ScatterChart from '@/charts/scatter-chart';
import HealthService from '../services/healthService';
import domains from '@/models/domains';

@Component({
    components: {
        Dropdown,
    },
    props: {
        path: String,
    },
})
export default class Analysis extends Vue {
    scatterChart: ScatterChart | null = null;

    healthService: HealthService = HealthService.get();

    expanded = false;

    xAxis = 'Wealth';

    xAxisDomainData: { [area: string]: number } = {};

    xAxisDomainDataCompare: { [area: string]: number } = {};

    yAxis = 'Health';

    viewType = 'scatter';

    xAxisTotal = 'value';

    yAxisTotal = 'value';

    dotSize = 'default';

    category = 'cluster'

    yAxisDomainData: { [area: string]: number } = {};

    yAxisDomainDataCompare: { [area: string]: number } = {};

    isCollapsed = true;

    hoveredY = '';

    hoveredX = '';

    // treatmentType: TreatmentType = TreatmentType['All specialties (total)'];

    async mounted (): Promise<void> {
        await this.healthService.loadData();
        this.scatterChart = new ScatterChart();
        this.scatterChart.initalise(
                this.$refs.chart as HTMLElement,
                this.$refs.tooltip as HTMLElement,
                this.chartData,
                this.xAxisTitle,
                this.yAxisTitle,
                this.axisUnit(this.xAxis),
                this.axisUnit(this.yAxis),
        );
    }

    @Watch('xAxis')
    @Watch('treatmentType')
    @Watch('xAxisYear')
    xAxisChanged (): void {
        if (!this.scatterChart) return;
        this.scatterChart.changeData(
            this.chartData,
            this.xAxisTitle,
            this.yAxisTitle,
            this.xAxisArrowText,
            this.yAxisArrowText,
            this.axisUnit(this.xAxis),
            this.axisUnit(this.yAxis),
        );
    }

    @Watch('xAxisTotal')
    @Watch('dotSize')
    @Watch('category')
    xAxisTotalChanged (): void {
        if (!this.scatterChart) return;
        this.scatterChart.changeData(
            this.chartData,
            this.xAxisTitle,
            this.yAxisTitle,
            this.xAxisArrowText,
            this.yAxisArrowText,
            this.axisUnit(this.xAxis),
            this.axisUnit(this.yAxis),
        );
    }

    @Watch('yAxisTotal')
    yAxisTotalChanged (): void {
        if (!this.scatterChart) return;
        this.scatterChart.changeData(
            this.chartData,
            this.xAxisTitle,
            this.yAxisTitle,
            this.xAxisArrowText,
            this.yAxisArrowText,
            this.axisUnit(this.xAxis),
            this.axisUnit(this.yAxis),
        );
    }

    get xAxisTitle (): string {
        return `${this.axisTitle(this.xAxis)}`;
    }

    get yAxisTitle (): string {
        return `${this.axisTitle(this.yAxis)}`;
    }

    xIsHovered (metric: string): void {
        this.hoveredX = metric;
    }

    yIsHovered (metric: string): void {
        this.hoveredY = metric;
    }

    @Watch('yAxis')
    @Watch('yAxisYear')
    async yAxisChanged (): Promise<void> {
        if (!this.scatterChart) return;
        this.scatterChart.changeData(
            this.chartData,
            this.xAxisTitle,
            this.yAxisTitle,
            this.xAxisArrowText,
            this.yAxisArrowText,
            this.axisUnit(this.xAxis),
            this.axisUnit(this.yAxis),
        );
    }

    get xAxisArrowText (): string {
        if (this.xAxis === 'Median age') {
            return 'Older';
        }
        if (this.xAxis === 'Population') {
            return 'Larger';
        }
        return (this.healthService.isHigherBetter(this.xAxis)) ? 'Better' : 'Worse';
    }

    get yAxisArrowText (): string {
        if (this.yAxis === 'Median age') {
            return 'Older';
        }
        if (this.yAxis === 'Population') {
            return 'Larger';
        }
        return (this.healthService.isHigherBetter(this.yAxis)) ? 'Better' : 'Worse';
    }

    get metrics (): Array<unknown> {
        const parents = domains.filter((a) => a.isParent);
        return parents.map((a) => ({ ...a, children: domains.filter((b) => b.category === a.name) }));
    }

    get axisOptions (): Array<unknown> {
        return [
            ...this.metrics,
            {
                value: 'Population', name: 'Population', icon: 'user-friends', color: '#2567c7', description: 'The number of people in each CCG.',
            },
            {
                value: 'Median age', name: 'Median age', icon: 'child', color: '#7ebed8', description: 'Median age of the CCG population, where half the CCG population are younger than this age and half the CCG population are older.',
            },
            // {
            //     value: 'ruralUrban', name: 'Rural Urban Index', icon: 'city', color: '#8a8a8a',
            // },

        ];
    }

    get categories (): Array<{ colour?: string; text: string }> {
        if (this.category === 'cluster') {
            return [
                { colour: this.scatterChart?.color('1'), text: 'Northern cities and surrounding areas, Midland cities and coastal cities' },
                { colour: this.scatterChart?.color('2'), text: 'Rural and coastal areas' },
                { colour: this.scatterChart?.color('3'), text: 'Inner city London boroughs, Bristol and Brighton' },
                { colour: this.scatterChart?.color('4'), text: 'The home counties and southern London boroughs' },
                { colour: this.scatterChart?.color('5'), text: 'Westminster, London (an outlier with very few residents)' },
            ];
        }
        return this.healthService.regions.map((region) => ({
            colour: this.scatterChart?.color(region), text: region,
        }));
    }

    isReverseAxis (axis: string): boolean {
        return axis === 'populationDensity' || axis === 'medianAge' || axis === 'imdAverageScore';
    }

    get isMobile (): boolean {
        return window.innerWidth < 900;
    }

    axisTitle (value: string): string {
        switch (value) {
        case 'medianAge': return 'Median age';
        case 'imdDecile': return 'IMD Decile';
        case 'imdAverageScore': return 'Deprivation score';
        case 'percentage': return 'Cumulative COVID-19 infections (%)';
        case 'population': return 'Population';
        default:
            return `${value}`;
        }
    }

    axisUnit (value: string): string {
        switch (value) {
        case 'medianAge': return 'Years';
        case 'population': return 'Total';
        default:
            return this.healthService.getDomain(value)?.unit ?? '';
        }
    }

    getAxis (areaCode: string, axis: string): number {
        const data = this.getDataForAxis(axis);
        return data![areaCode];
    }

    getDataForAxis (axis: string): Record<string, number> {
        return this.healthService.getAreaValues(axis);
    }

    get sizeExents (): [number, number] {
        if (this.dotSize === 'population') {
            const populationsByArea = Object.values(this.getDataForAxis('Population') ?? {});
            return d3.extent(populationsByArea.filter((a) => a !== null) as Array<number>) as unknown as [number, number];
        }
        if (this.dotSize === 'imdAverageScore') {
            const imdScoreByArea = Object.values(this.getDataForAxis('IMD') ?? {});
            return d3.extent(imdScoreByArea.filter((a) => a !== null) as Array<number>) as unknown as [number, number];
        }
        return [0, 0];
    }

    getSize (areaCode: string): number {
        if (this.dotSize === 'population') {
            const pop = this.getAxis(areaCode, 'Population');
            return ((pop || 0) / this.sizeExents[1]) * 20;
        }
        if (this.dotSize === 'imdAverageScore') {
            const score = this.getAxis(areaCode, 'IMD');
            return ((score || 0) / this.sizeExents[1]) * 14;
        }
        return 7;
    }

    get chartData (): Array<ScatterChartData> {
        const data: ScatterChartData[] = [];
        this.healthService.areaCodes.forEach((areaCode) => {
            const xValue = this.getAxis(areaCode, this.xAxis);
            const yValue = this.getAxis(areaCode, this.yAxis);

            // if (xValue === null || yValue === null) return;

            data.push({
                name: this.healthService.areaName(areaCode),
                x: xValue as number,
                y: yValue as number,
                category: this.category === 'cluster' ? this.healthService!.getAreaValue(areaCode, 'Cluster5').toString() : this.healthService.getAreaRegion(areaCode),
                size: this.getSize(areaCode),
            });
        });

        return data;
    }
}
