export default class Resize {
    static resizeRight (ele: HTMLElement, cb: (width: number) => void): void {
        // The current position of mouse
        let x = 0;

        // The dimension of the element
        let w = 0;

        const mouseMoveHandler = (e: MouseEvent) => {
            // How far the mouse has been moved
            const dx = e.clientX - x;
            const parentWidth = ele.parentElement?.clientWidth ?? window.innerWidth * 0.5;
            // Adjust the dimension of element
            const width = Math.min(Math.max(w + dx, parentWidth * 0.03), parentWidth * 0.97);
            ele.style.width = `${width}px`;
            cb(width);
            // ele.style.height = `${h + dy}px`;
        };

        const mouseUpHandler = () => {
            // Remove the handlers of `mousemove` and `mouseup`
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        // Handle the mousedown event
        // that's triggered when user drags the resizer
        const mouseDownHandler = (e: MouseEvent) => {
            // Get the current mouse position
            x = e.clientX;

            // Calculate the dimension of element
            const styles = window.getComputedStyle(ele);
            w = parseInt(styles.width, 10);

            // Attach the listeners to `document`
            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };
        ele.addEventListener('mousedown', mouseDownHandler);
    }
}
