<template>
  <div id="app">
    <nav role="navigation">
        <font-awesome-icon :icon="['fad', 'bars']" class="hamburger" @click="showMenu = !showMenu"/>
        <ul v-show="showMenu" @click="showMenu = false">
            <li>
                <router-link to="/" aria-label="Home" title="Home">
                    <font-awesome-icon :icon="['fad', 'home']" />
                </router-link>
            </li>
            <li>
                <router-link to="/analysis" aria-label="Analyse" title="Analyse">
                    <font-awesome-icon :icon="['fad', 'chart-scatter']" />
                </router-link>
            </li>
            <li>
                <router-link to="/about" aria-label="About" title="About">
                    <font-awesome-icon :icon="['fad', 'info-circle']" />
                </router-link>
            </li>
            <li class="bottom">
                <router-link to="/terms" aria-label="Terms" title="Terms">
                    <font-awesome-icon :icon="['fad', 'file-alt']" />
                </router-link>
            </li>
        </ul>
    </nav>
    <router-view/>
    <div class="cookies" v-if="showCookies">
        <p>We'd like to use cookies which help us understand how our website is used by our visitors. You can find out more about cookies in our <a href="/terms">privacy policy</a>. By continuing to use this site you are giving us your consent to do this.</p>
        <button @click="dismissCookies">Ok</button>
    </div>
  </div>
</template>
<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
    data () {
        return {
            showMenu: false,
            showCookies: true,
        };
    },
    created () {
        if (window.localStorage.getItem('cookie')) {
            this.showCookies = false;
        }
    },
    methods: {
        dismissCookies () {
            window.localStorage.setItem('cookie', 1);
            this.showCookies = false;
        },
    },
};
</script>
<style lang="scss">
@import '@/scss/reset.scss';
@import '@/scss/colours.scss';

html, body {
    height: 100%;
}

*::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: #ddd;        /* color of the tracking area */
  border-radius: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 0.5rem;
  border: 3px solid #ddd;
}

#app {
    background: #fff;
    font-family: 'Work Sans', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    height: 100%;
    color: #2c3e50;
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: flex;
    @media(max-width: 1000px) {
        flex-direction: column;
    }
}

.hamburger {
    display: none;
}

button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    font-family: 'Work Sans', sans-serif;
}

nav {
    background: #fff;
    height: 100%;
    border-right: 1px solid #eee;
    z-index: 3;
    @media(max-width: 1000px) {
        background: #f4f4f4;
        height: 40px;
        box-shadow: rgba(0, 0, 0, 0.2) 7px 6px 7px;
        .hamburger {
            display: block;
            margin: 0.5rem;
            font-size: 2rem;
        }
        ul {
            position: fixed;
            top: 40px;
            background: #f4f4f4;
            z-index: 2;
        }
        li {
            position: relative !important
        }
    }
    ul {
        list-style: none;
        @media (min-width: 1001px) {
            display: block !important;
        }
    }

    li {
        font-size: 2rem;
        color: #666;
        text-align: center;
        padding: 1rem;
        &:hover {
            background: #fff;
        }
        &.bottom {
            position: absolute;
            bottom: 0;
        }
    }

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: $lcpBlue;
        }
    }
}

.cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: #eee;
    z-index: 99;
    padding: 1rem;
    border-top: 1px solid #eee;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 10px;
    text-align: center;
    p {
        text-align: left;
    }
    button {
        background: $lcpBlue;
        color: #fff;
        padding: 0.5rem 1rem;
        margin-top: 1rem;
        border-radius: 0.25rem;
    }
}
</style>
