



















import { Component, Vue, Prop } from 'vue-property-decorator';
import HealthService from '../services/healthService';

@Component({
    components: {
    },
})
export default class RankingTable extends Vue {
    @Prop()
    areaCodes!: Array<string>;

    @Prop()
    selectedArea!: string;

    @Prop()
    metric!: string;

    selectedAreas: string[] = [];

    healthService: HealthService = HealthService.get();

    areaName (areaCode: string): string {
        return this.healthService.areaName(areaCode);
    }

    areaValue (areaCode: string): number {
        return this.healthService.getAreaValue(areaCode, this.metric);
    }

    formatterAreaValue (areaCode: string): string {
        return this.healthService.getFormattedAreaValue(areaCode, this.metric);
    }

    areaRank (areaCode: string): number {
        return this.healthService.getAreaRank(areaCode, this.metric) + 1;
    }

    barStyle (areaCode: string): Record<string, string> {
        const start = this.healthService.getLowestValueForMetric(this.metric);
        const outOf = this.healthService.getHighestValueForMetric(this.metric) - start;
        const value = this.areaValue(areaCode);
        return {
            width: `${((value - start) / outOf) * 100}%`,
            background: this.healthService.getColour(value, this.metric),
        };
    }

    gotoArea (areaCode: string): void {
        this.$emit('gotoArea', areaCode);
    }
}
