


























import { Component, Vue, Prop } from 'vue-property-decorator';
import { DonutData } from '@/models/donutData';
import HealthService from '../services/healthService';
import DonutChart from './donut-chart.vue';
import RankingTable from './ranking-table.vue';

@Component({
    components: {
        DonutChart,
        RankingTable,
    },
})
export default class RankingTale extends Vue {
    @Prop()
    areaCode!: string;

    healthService: HealthService = HealthService.get();

    selectedMetric: string | null = null;

    get donutData (): DonutData {
        return this.healthService.getDonutData(this.areaCode);
    }

    get ranking (): number {
        if (!this.selectedMetric) return 0;
        return this.healthService.getAreaRank(this.areaCode, this.selectedMetric);
    }

    get metricDescription (): string {
        if (!this.selectedMetric) return '';
        return this.healthService.getDomain(this.selectedMetric)?.unit ?? '';
    }

    get areaName (): string {
        return this.healthService.areaName(this.areaCode);
    }

    get rankings (): Array<string> {
        if (!this.selectedMetric) return [];
        return this.healthService.getSortedAreas(this.selectedMetric);
    }

    get rankingsTable (): Array<unknown> {
        const areas = [];
        if (!this.selectedMetric) return [];
        const r = Math.min(Math.max(this.ranking - 5, 0), this.healthService.data.length - 9);
        let count = 10;
        if (this.ranking > 5) {
            count -= 1;
            areas.push(this.rankings[0]);
        }
        for (let i = r; i < r + count; i += 1) {
            if (this.rankings && this.rankings[i]) {
                areas.push(this.rankings[i]);
            }
        }
        if (this.rankings.length && areas[areas.length - 1] !== this.rankings[this.rankings.length - 1]) {
            areas.splice(areas.length - 1, 1);
            areas.push(this.rankings[this.rankings.length - 1]);
        }
        return areas;
    }

    metricSelected (metric: string): void {
        this.selectedMetric = metric;
    }

    close (): void {
        this.$emit('close');
    }

    gotoArea (areaCode: string): void {
        this.$emit('gotoArea', areaCode);
    }
}
