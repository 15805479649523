import Vue from 'vue';
import MapChart from '@lcp/map-chart';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faHome, faMapMarkedAlt, faExpandAlt, faChartScatter, faTriangle,
    faHistory, faTimes, faMapMarkerAlt, faTombstone, faChartLine, faWalking,
    faWheelchair, faSmile, faHeadSide, faHeadSideBrain, faBaby, faWeightHanging,
    faCocktail, faUserHardHat, faPoundSign, faUserInjured, faCoins, faMoneyBill,
    faMapSigns, faShapes, faDiploma, faHospitalUser, faRacquet, faMoneyBillWave,
    faUniversalAccess, faHouseLeave, faHomeLg, faUserMd, faHeartbeat, faCaretDown,
    faSlash, faGraduationCap, faPiggyBank, faBuilding, faHeart, faInfoCircle,
    faFileAlt, faChild, faUserFriends, faBars, faQuestionCircle, faLocation, faSearch,
    faShare, faExchange, faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight,
    faAngleLeft, faAngleRight, faHospital, faHandsHelping,
} from '@fortawesome/pro-duotone-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from './router';
import App from './App.vue';
import clickOutside from './directives/click-outside';
import './directives/focus';

clickOutside(Vue);
library.add(faHome, faMapMarkedAlt, faExpandAlt, faChartScatter, faTriangle,
    faHistory, faMapMarkerAlt, faTimes, faTombstone, faChartLine, faWalking,
    faWheelchair, faSmile, faHeadSide, faHeadSideBrain, faBaby, faWeightHanging,
    faCocktail, faUserHardHat, faPoundSign, faUserInjured, faCoins, faMoneyBill,
    faMapSigns, faShapes, faDiploma, faHospitalUser, faRacquet, faMoneyBillWave,
    faUniversalAccess, faHouseLeave, faHomeLg, faUserMd, faHeartbeat, faCaretDown,
    faSlash, faGraduationCap, faPiggyBank, faBuilding, faHeart, faInfoCircle, faFileAlt,
    faChild, faUserFriends, faBars, faQuestionCircle, faLocation, faSearch, faShare,
    faExchange, faGraduationCap, faBuilding, faChevronLeft, faChevronRight, faAngleDoubleLeft,
    faAngleDoubleRight, faAngleLeft, faAngleRight, faHospital, faHandsHelping);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(MapChart);

new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
