import { Map } from '@lcp/map-chart';
import * as d3 from 'd3';
import { DSVRowArray } from 'd3';
import { DonutData } from '@/models/donutData';
import domains from '@/models/domains';
import Domain from '@/models/domain';

export default class HealthService {
    static heathService: HealthService;

    data: Array<Record<string, string | number>> = [];

    dataByArea: Record<string, Record<string, string| number>> = {};

    postcodes: Array<{postcode: string; areaCode: string; areaName: string }> = [];

    areaNames: Record<string, string> = {};

    sortedMetrics: Record<string, Array<string>> = {};

    map!: Map;

    areaStats!: Array<{ areaCode: string; population: number; medianAge: number; }>

    constructor () {
        this.extentsFunction = this.extentsFunction.bind(this);
        this.map = new Map({});
    }

    static get (): HealthService {
        if (!HealthService.heathService) HealthService.heathService = new HealthService();
        return HealthService.heathService;
    }

    get areaCodes (): Array<string> {
        return this.data.map((a) => a.areaCode as string);
    }

    get regions (): Array<string> {
        return [...new Set(this.data.map((a) => a.Region as string))];
    }

    async loadData (): Promise<void> {
        await d3.csv('/data.csv').then((result: DSVRowArray<string>) => {
            this.data = result as Array<Record<string, string | number>>;
            this.data.forEach((row) => {
                this.dataByArea[row.areaCode] = row;
            });
        });

        await d3.csv('/postcodes.csv').then((result: DSVRowArray<string>) => {
            this.postcodes = result as unknown as Array<{postcode: string; areaCode: string; areaName: string }>;
            this.postcodes.forEach((row) => {
                this.areaNames[row.areaCode] = row.areaName;
            });
        });
    }

    extentsFunction (val: boolean, metric: string | Array<string>): [number, number, number] {
        let m = metric;
        if (Array.isArray(metric)) m = metric[0];
        const data = Object.values(this.getAreaValues(m as string));
        let total = 0;
        let min = 9999;
        let max = 0;
        data.forEach((a) => {
            total += a;
            if (a < min) min = a;
            if (a > max) max = a;
        });
        if (this.isHigherBetter(m as string)) return [min, total / data.length, max];
        return [max, total / data.length, min];
    }

    isHigherBetter (metric: string): boolean {
        return domains.find((a) => a.name === metric)?.higherBetter ?? false;
    }

    getAreaValues (metric: string): Record<string, number> {
        const data: Record<string, number> = {};
        this.data.forEach((row) => {
            data[row.areaCode] = this.getAreaValue(row.areaCode as string, metric);
        });
        return data;
    }

    getParentValue (areaCode: string, metric: string, childDomains?: Array<Domain>): number {
        const filteredDomains = childDomains ?? domains.filter((a) => a.category === metric);
        return filteredDomains.reduce((c, d) => c + this.getAreaRank(areaCode, d.name), 0) / filteredDomains.length;
    }

    getAreaRegion (areaCode: string): string {
        if (!this.dataByArea[areaCode]) return areaCode;
        return this.dataByArea[areaCode].Region as string;
    }

    getAreaValue (areaCode: string, metric: string): number {
        if (this.getDomain(metric)?.isParent) {
            return this.getParentValue(areaCode, metric);
        }
        return Number(this.dataByArea[areaCode][metric]);
    }

    formatNumber (value: number): string {
        return (Math.round((value * 100)) / 100).toLocaleString();
    }

    getFormattedAreaValue (areaCode: string, metric: string): string {
        return this.formatNumber(this.getAreaValue(areaCode, metric));
    }

    getAreaRank (areaCode: string, metric: string): number {
        return this.getSortedAreas(metric).indexOf(areaCode);
    }

    getDomain (domain: string): Domain {
        return domains.find((a) => a.name === domain) as Domain;
    }

    getColour (value: number, metric: string): string {
        return this.map.getColour(value, this.extentsFunction, false, metric) ?? '#ccc';
    }

    getHighestValueForMetric (metric: string): number {
        const data = this.getSortedAreas(metric);
        const areaCode = data[0];
        return this.getAreaValue(areaCode, metric);
    }

    getLowestValueForMetric (metric: string): number {
        const data = this.getSortedAreas(metric);
        const areaCode = data[data.length - 1];
        return this.getAreaValue(areaCode, metric);
    }

    topForMetric (metric: string, quantity = 10): Array<string> {
        return this.getSortedAreas(metric).slice(0, quantity);
    }

    bottomForMetric (metric: string, quantity = 10): Array<string> {
        return [...this.getSortedAreas(metric)].reverse().slice(0, quantity).reverse();
    }

    areaName (areaCode: string): string {
        return this.areaNames[areaCode];
    }

    getSortedAreas (metric: string): Array<string> {
        if (!this.sortedMetrics[metric]?.length) {
            if (this.getDomain(metric)?.isParent) {
                const filteredDomains = domains.filter((a) => a.category === metric);
                this.sortedMetrics[metric] = this.areaCodes
                    .sort((a, b) => (
                        this.getParentValue(a, metric, filteredDomains)
                        < this.getParentValue(b, metric, filteredDomains) ? -1 : 1
                    ));
            } else {
                this.sortedMetrics[metric] = [...this.data]
                    .sort((a, b) => (Number(a[metric]) < Number(b[metric]) ? 1 : -1)).map((a) => a.areaCode as string);
                if (!this.isHigherBetter(metric)) this.sortedMetrics[metric].reverse();
            }
        }
        return this.sortedMetrics[metric];
    }

    getDonutData (area: string): DonutData {
        const categories = [...new Set(domains.map((a) => a.category))].filter((a) => a !== 'Root');
        const donut: DonutData = {
            name: '',
            count: categories.length,
            sum: categories.length,
            domain: '',
            value: 0,
            children: [],
        };

        categories.forEach((category) => {
            const subDomains = domains.filter((a) => a.category === category);
            const sum = subDomains.reduce((a, b) => a + this.getAreaRank(area, b.name), 0);
            const val = sum / subDomains.length;
            const domain : DonutData = {
                name: category,
                count: subDomains.length,
                sum,
                domain: category,
                value: val,
                children: [],
                colour: this.map.getColour(val, () => [this.data.length - 1, this.data.length / 2, 0]),
            };
            subDomains.forEach((sub) => {
                domain.children.push({
                    name: sub.name,
                    count: 1,
                    sum: this.getAreaValue(area, sub.name),
                    domain: category,
                    value: this.getAreaValue(area, sub.name),
                    subdomain: sub.name,
                    children: [],
                });
            });

            donut.children.push(domain);
        });
        return donut;
    }
}
