



























































































import { MapChart, MapOptions } from '@lcp/map-chart';
import { Component, Vue } from 'vue-property-decorator';
import HealthService from '../services/healthService';
import Resize from '../util/resize';
import RankingTable from '../components/ranking-table.vue';
import AreaBreakdown from '@/components/area-breakdown.vue';
import Dropdown from '@/components/dropdown.vue';
import domains from '@/models/domains';
import Domain from '@/models/domain';
import Postcodes from '@/services/postcodes';

@Component({
    components: {
        RankingTable,
        AreaBreakdown,
        Dropdown,
    },
})
export default class Home extends Vue {
    selectedAreas: string[] = [];

    selectedRegion: string[] = [];

    healthService: HealthService = HealthService.get();

    selectedLeftMetric = 'Health';

    selectedRightMetric = 'Wealth';

    hoveredArea: string | null = null;

    loading = false;

    tooltipStyle: Record<string, string> = {};

    rightMetricPos: number | null = null;

    postcodeSearch = '';

    dismissPostcodes = false;

    postcodes!: Postcodes;

    compareRight = false;

    async mounted (): Promise<void> {
        this.loading = true;
        await this.healthService.loadData();
        this.loading = false;
        this.postcodes = await Postcodes.get();
        this.$nextTick(() => {
            Resize.resizeRight(this.$refs.overlay as HTMLElement, (width: number) => {
                this.rightMetricPos = width;
            });
        });
    }

    get metrics (): Array<unknown> {
        const parents = domains.filter((a) => a.isParent);
        return parents.map((a) => ({ ...a, children: domains.filter((b) => b.category === a.name) }));
    }

    get selectedArea (): string | null {
        return this.selectedAreas?.length ? this.selectedAreas[0] : null;
    }

    get rightMetricStyle (): Record<string, string> {
        return {
            left: `${this.rightMetricPos}px`,
        };
    }

    get postcodeAreas (): Array<unknown> {
        if (this.postcodeSearch?.length < 2) return [];
        const areasFromPostCode = this.postcodes?.getAreaCodeFromPostcode(this.postcodeSearch).slice(0, 10);
        if (!areasFromPostCode) return [];
        return areasFromPostCode;
    }

    mapOptions (map: string): MapOptions {
        return {
            topoJsonSettings: {
                jsonPath: '/utla.json',
                featureCollectionName: 'subunits',
                areaPropertyName: 'ctyua19cd',
            },
            display: {
                unselectedColour: 'rgb(144, 144, 144)',
                hiddenAreas: [
                    'S12000027',
                ],
                zoomLevel: 0.9,
            },
            extents: {
                extentsFunction: this.healthService.extentsFunction,
                extentsFunctionParams: [map === 'left' ? this.selectedLeftMetric : this.selectedRightMetric],
            },
            areaGroups: {
                hasRegions: true,
                getRegionForArea: (areaCode: string) => this.healthService.getAreaRegion(areaCode),
            },
            interactive: {
                allowPan: false,
                allowSelectRegion: true,
                allowZoom: false,
                disabledAreasFunction: (area: string) => !area.startsWith('E'),
            },
            events: {
                areaSelected: (areas) => {
                    if (areas === this.selectedAreas || (!areas?.length && !this.selectedAreas?.length)) return;
                    this.selectedAreas = areas;
                    if (areas.length) {
                        if (map === 'left') {
                            (this.$refs.map2 as MapChart).selectRegions([this.healthService.getAreaRegion(areas[0])], false);
                            (this.$refs.map2 as MapChart).selectAreas(areas, false);
                        }
                        if (map === 'right') {
                            (this.$refs.map2 as MapChart).selectRegions([this.healthService.getAreaRegion(areas[0])], false);
                            (this.$refs.map as MapChart).selectAreas(areas, false);
                        }
                    } else {
                        if (map === 'left') (this.$refs.map2 as MapChart).deselect();
                        if (map === 'right') (this.$refs.map as MapChart).deselect();
                    }
                },
                regionSelected: (regions) => {
                    if (regions === this.selectedRegion || (!regions?.length && !this.selectedRegion?.length)) return;
                    this.selectedRegion = regions;
                    if (regions.length) {
                        if (map === 'left') (this.$refs.map2 as MapChart).selectRegions(regions, false);
                        if (map === 'right') (this.$refs.map as MapChart).selectRegions(regions, false);
                    } else {
                        if (map === 'left') (this.$refs.map2 as MapChart).deselect();
                        if (map === 'right') (this.$refs.map as MapChart).deselect();
                    }
                },
                areaHovered: (area: string | null, e?: MouseEvent) => {
                    if (area === this.hoveredArea) return;
                    this.hoveredArea = area;
                    this.tooltipStyle = { left: `${e?.offsetX}px`, top: `${e?.offsetY}px` };
                },
            },
        };
    }

    exchange (): void {
        const left = this.selectedLeftMetric;
        this.selectedLeftMetric = this.selectedRightMetric;
        this.selectedRightMetric = left;
    }

    singleSelect (metric: string): void {
        this.showHideCompareRight();
        if (this.selectedRightMetric === metric) {
            this.exchange();
        }
    }

    areaName (areaCode: string): string {
        return this.healthService.areaName(areaCode);
    }

    areaValue (areaCode: string, metric: string): string {
        return this.healthService.getFormattedAreaValue(areaCode, metric);
    }

    areaRank (areaCode: string, metric: string) : number {
        return this.healthService.getAreaRank(areaCode, metric);
    }

    areaColour (areaCode: string, metric: string): string {
        return this.healthService.getColour(this.healthService.getAreaValue(areaCode, metric), metric);
    }

    domainDescription (domain: string): string {
        return this.domain(domain)?.unit ?? '';
    }

    domain (domain: string): Domain {
        return this.healthService.getDomain(domain);
    }

    top10 (metric: string): Array<string> {
        return this.healthService.topForMetric(metric, 5);
    }

    bottom10 (metric: string): Array<string> {
        return this.healthService.bottomForMetric(metric, 5);
    }

    mapData (metric: string): Record<string, number> {
        return this.healthService.getAreaValues(metric);
    }

    showHideCompareRight (): void {
        this.compareRight = !this.compareRight;
        this.$nextTick(() => {
            (this.$refs.map as MapChart).resize();

            (this.$refs.map2 as MapChart).resize();
        });
    }

    getLocation (): void {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (this.mapData && this.postcodes) {
                    const area = (this.$refs.map as MapChart).getAreaByLatLong(position.coords.latitude, position.coords.longitude);
                    if (area) {
                        this.gotoArea(area);
                    }
                }
            });
        }
    }

    clearArea (): void {
        (this.$refs.map2 as MapChart).deselect();
        (this.$refs.map as MapChart).deselect();
    }

    gotoArea (areaCode: string): void {
        (this.$refs.map as MapChart).selectAreas([areaCode]);
    }
}
