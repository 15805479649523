const domains = [
    {
        name: 'Health',
        icon: 'heartbeat',
        color: '#e871ad',
        category: 'Root',
        isParent: true,
        unit: 'Average ranking of all health metrics',
    },
    {
        name: 'Skills and work',
        icon: 'building',
        color: '#2a9e59',
        category: 'Root',
        isParent: true,
        unit: 'Average ranking of all skills and work metrics',
    },
    {
        name: 'Wealth',
        icon: 'piggy-bank',
        color: '#e4ad2f',
        category: 'Root',
        isParent: true,
        unit: 'Average ranking of all wealth metrics',
    },
    {
        name: 'Welfare',
        icon: 'hands-helping',
        color: '#00a6c9',
        category: 'Root',
        isParent: true,
        unit: 'Average ranking of all welfare metrics',
    },
    {
        name: 'Health infrastructure',
        icon: 'hospital',
        color: '#7ebed8',
        category: 'Root',
        isParent: true,
        unit: 'Average ranking of all health infrastructure metrics',
    },
    {
        name: 'Healthy life expectancy',
        category: 'Health',
        icon: 'heart',
        unit: 'Years',
        color: '#e871ad',
        higherBetter: true,
    },
    {
        name: 'Life expectancy',
        category: 'Health',
        icon: 'chart-line',
        unit: 'Years',
        color: '#e871ad',
        higherBetter: true,
    },
    {
        name: 'Physical health conditions (HI)',
        category: 'Health',
        icon: 'walking',
        color: '#e871ad',
        higherBetter: true,
        unit: 'Health index score',
    },
    {
        name: 'Disability that impacts daily activities',
        category: 'Health',
        icon: 'wheelchair',
        unit: '% aged 16-64 who are EA core or work-limiting disabled',
        color: '#e871ad',
        higherBetter: false,
    },
    {
        name: 'Life satisfaction',
        category: 'Health',
        icon: 'smile',
        unit: 'Mean score of respondants',
        color: '#e871ad',
        higherBetter: true,
    },
    {
        name: 'Wellbeing (HI)',
        category: 'Health',
        icon: 'head-side',
        color: '#e871ad',
        unit: 'Health index score',
        higherBetter: true,
    },
    {
        name: 'Suicides',
        category: 'Health',
        icon: 'tombstone',
        unit: 'Deaths per 100,000 of the population aged 10 years and over',
        color: '#e871ad',
        higherBetter: false,
    },
    {
        name: 'Depression',
        category: 'Health',
        icon: 'head-side-brain',
        unit: '% of patients aged 18 or over with a new diagnosis of depression in year',
        color: '#e871ad',
        higherBetter: false,
    },
    {
        name: 'Infant mortality',
        category: 'Health',
        icon: 'baby',
        unit: 'Per 1,000 live births',
        color: '#e871ad',
        higherBetter: false,
    },
    {
        name: 'Overweight and obesity in children',
        category: 'Health',
        icon: 'weight-hanging',
        unit: '% of obesity in children in year 6',
        color: '#e871ad',
        higherBetter: false,
    },
    {
        name: 'Behavioural risk factors (HI)',
        category: 'Health',
        icon: 'cocktail',
        color: '#e871ad',
        unit: 'Health index score',
        higherBetter: true,
    },
    {
        name: 'Unemployment',
        category: 'Skills and work',
        icon: 'user-hard-hat',
        secondaryIcon: 'slash',
        unit: '% unemployed',
        color: '#2a9e59',
        higherBetter: false,
    },
    {
        name: 'Workplace safety',
        category: 'Skills and work',
        icon: 'user-injured',
        unit: 'rate of reported non-fatal injuries per 100,000 employees',
        color: '#2a9e59',
        higherBetter: false,
    },
    {
        name: 'Child poverty',
        category: 'Welfare',
        icon: 'pound-sign',
        seconaryIcon: 'slash',
        unit: '% of children aged 0 to 15 years living in families with absolute low income',
        color: '#00a6c9',
        higherBetter: false,
    },
    {
        name: 'Total wealth',
        category: 'Wealth',
        icon: 'coins',
        color: '#e4ad2f',
        higherBetter: true,
        unit: '£m per person',
    },
    {
        name: 'GDP',
        category: 'Wealth',
        icon: 'money-bill',
        color: '#e4ad2f',
        higherBetter: true,
        unit: '£100,000 per person',
    },
    {
        name: 'Public green space',
        category: 'Welfare',
        icon: 'map-signs',
        unit: 'Average distance (M2)',
        color: '#00a6c9',
        higherBetter: false,
    },
    {
        name: 'Distance to GP services',
        category: 'Health infrastructure',
        icon: 'hospital-user',
        unit: 'Travel time in to nearest GP (minutes)',
        color: '#7ebed8',
        higherBetter: false,
    },
    {
        name: 'Distance to sports and leisure facilities',
        category: 'Health infrastructure',
        icon: 'racquet',
        unit: 'Travel time to nearest sports/leisure centre (minutes',
        color: '#7ebed8',
        higherBetter: false,
    },
    {
        name: 'IMD',
        unit: 'Indicies of deprivation score',
        category: 'Welfare',
        icon: 'money-bill-wave',
        color: '#00a6c9',
        higherBetter: false,
    },
    {
        name: 'PIP + DLA',
        category: 'Welfare',
        icon: 'universal-access',
        color: '#00a6c9',
        higherBetter: false,
        unit: '£m per person',
    },
    {
        name: 'Rough sleeping',
        category: 'Wealth',
        icon: 'house-leave',
        unit: 'Total',
        color: '#e4ad2f',
        higherBetter: false,
    },
    {
        name: 'Household income',
        unit: 'Average gross disposible income',
        category: 'Wealth',
        icon: 'home-lg',
        color: '#e4ad2f',
        higherBetter: true,
    },
    {
        name: 'NHS spend per head',
        category: 'Health infrastructure',
        icon: 'user-md',
        color: '#7ebed8',
        higherBetter: true,
        unit: '£ per head',
    },
    {
        name: 'Early years development',
        category: 'Welfare',
        icon: 'shapes',
        unit: '% of 5-year olds achieving a good level of development',
        color: '#00a6c9',
        higherBetter: true,
    },
    {
        name: 'NVQ4+',
        category: 'Skills and work',
        icon: 'diploma',
        color: '#2a9e59',
        higherBetter: true,
    },
];

export default domains;
