<template>

<div class="dropdown-item" :class="{selected: item === selected}" ref="item" v-if="item">
    <div class="name" @mouseover="thisHovered" @click.stop="itemSelected(item)" :title="item[nameKey]">
        <div class="icon" v-if="item.icon" :style="{ background: item.color }" >
            <font-awesome-icon :icon="['fad', item.icon]" />
            <font-awesome-icon class="secondary-icon" v-if="item.secondaryIcon" :icon="['fad', item.secondaryIcon]" />
        </div>
        {{ item[nameKey] }}
        <div class="expand" v-if="item.children && item.children.length" @click.stop="$emit('hovered-item', item)">
            <font-awesome-icon :icon="['fad', 'chevron-right']" />
        </div>
    </div>
    <div class="dropdown-child-wrapper" ref="dropdown" :style="getDropdownStyle" v-if="item.children && item.children.length && isHovered" >
        <div class="dropdown-child" >
            <div class="dropdown-item back" @click.stop="goBack">
                <div class="name">
                    <div class="shrink">
                        <font-awesome-icon :icon="['fad', 'chevron-left']" />
                    </div>
                    {{ parent ? parent[nameKey] : 'Back' }}
                </div>
            </div>
            <dropdown-item v-for="item in item.children" :key="item[valueKey]" :item="item" :hovered="hovered" :selected="selected"
                :valueKey="valueKey" :nameKey="nameKey" :value="value" @hovered-item="hoveredItem" @item-selected="itemSelected"
                :parent="item" :grandparent="parent"
            />
        </div>
    </div>
</div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { generateGuid } from '../directives/click-outside';

export default {
    props: {
        value: String,
        item: Object,
        valueKey: String,
        nameKey: String,
        hovered: Object,
        parent: Object,
        grandparent: Object,
        selected: Object,
    },
    name: 'dropdown-item',
    data () {
        return {
            id: '',
            dirty: false,
            selectedValue: '',
            focused: false,
        };
    },
    created () {
        this.id = generateGuid();
    },
    watch: {
        selectedValue () {
            this.dirty = true;
            this.$emit('input', this.selectedValue ? this.selectedValue[this.valueKey] : null);
        },
        value: {
            handler () {
                const item = this.findItem(this.item?.children, this.value);
                this.selectedValue = item;
            },
            immediate: true,
        },
        selected () {
            this.scrollToItem();
        },
    },
    computed: {
        isHovered () {
            const checkChildren = (item) => {
                if (item === this.hovered) return true;
                if (!item.children) return false;
                for (let i = 0; i < item.children.length; i += 1) {
                    if (checkChildren(item.children[i])) return true;
                }
                return false;
            };
            return checkChildren(this.item);
        },
        getDropdownStyle () {
            if (!this.hovered || !this.$refs.item) return {};
            let width = this.$refs.item.parentElement.parentElement.getBoundingClientRect().width;
            let left = this.$refs.item.parentElement.parentElement.getBoundingClientRect().left + this.$refs.item.parentElement.parentElement.getBoundingClientRect().width;
            const top = this.$refs.item.parentElement.parentElement.getBoundingClientRect().top;
            if (window.innerWidth < 1100) {
                left = this.$refs.item.parentElement.parentElement.getBoundingClientRect().left;
            } else if (width + left > window.innerWidth && window.innerWidth >= 1100) {
                left = this.$refs.item.parentElement.parentElement.getBoundingClientRect().left - width;
            } else {
                width = 'auto';
            }
            return {
                width: width === 'auto' ? width : `${width}px`,
                left: `${left + 2}px`,
                top: `${top}px`,
            };
        },
    },
    methods: {
        findItem (items, value) {
            if (!items) return null;
            for (let i = 0; i < items.length; i += 1) {
                if (items[i][this.valueKey] === value) return items[i];
                const item = this.findItem(items[i].children, value);
                if (item) return item;
            }
            return null;
        },
        goBack () {
            this.$emit('hovered-item', this.grandparent);
        },
        clickedOutside () {
            this.focused = false;
            this.$emit('hovered-item', null);
        },
        thisHovered () {
            if (window.innerWidth < 1100) return;
            this.$emit('hovered-item', this.item);
        },
        hoveredItem (item) {
            this.$emit('hovered-item', item);
        },
        scrollToItem () {
            if (this.selected === this.item) {
                const el = this.$refs.item;
                if (el) {
                    const topPos = el.offsetTop;
                    el.parentElement.scrollTop = topPos - el.offsetHeight;
                }
            }
        },
        enterPressed () {
            if (this.selected) {
                this.selectedValue = this.selected;
            }
            this.focused = false;
            this.$refs.input.blur();
        },
        tabPressed () {
            this.focused = false;
        },
        itemSelected (item) {
            this.$emit('item-selected', item);
        },
        caretMoved () {
            this.dirty = true;
            // this.showList();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '@/scss/colours.scss';
    .dropdown-child-wrapper {
        position: fixed;
        background: white;
        height: 16.5rem;
        width: 100%;
        top: 2.75rem;
        z-index: 2;
        box-shadow: rgba(0, 0, 0, 0.4) 0 4px 1rem;
    }

    .dropdown-child {
        overflow: auto;
        width: auto;
        padding: 0.25rem;
        height: 100%;
    }

    .dropdown-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .name {
            color: #333;
            padding: 0.75rem;
            font-size: 1rem;
            white-space: nowrap;
            user-select: none;
            display: flex;
            width: 100%;
            overflow: hidden;
            align-items: center;
            padding-right: 3rem;
            position: relative;
        }
        .expand {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 2rem;
            padding: 0.5rem;
            svg {
                color: #333;
            }
        }
        .shrink {
            position: absolute;
            left: 1rem;
            svg {
                color: #333;
            }
        }
        &.back {
            @media(min-width: 1099px) {
                display: none;
            }
            border-bottom: 1px solid #eee;
            .name {
                padding-left: 2.5rem;
            }
        }
        ul {
            padding-left: 4rem;
        }
        .icon {
            background: $lcpBlue;
            padding: 0.25rem;
            border-radius: 100%;
            width: 35px;
            min-width: 35px;
            height: 35px;
            text-align: center;
            margin-right: 1rem;
            line-height: 2.2;
            position: relative;
        }
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.2rem;
            color: #fff;
        }
        .secondary-icon {
            position: absolute;
            font-size: 0.8rem;
            color: darken($lcpBlue, 10);
        }
        &:hover  > .name {
            cursor: pointer;
            background: darken($lcpGrey, 10);
            color: white;
            .expand svg, .shrink svg {
                color: #fff;
            }
        }
    }
    .selected > .name {
        background: #666;
        color: #fff;
        .expand svg, .shrink svg {
            color: #fff;
        }
    }
</style>
