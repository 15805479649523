import * as d3 from 'd3';
import { HierarchyRectangularNode } from 'd3';
import { DonutData } from '@/models/donutData';

interface ArcData {
    x0: number;
    x1: number;
    y0: number;
    y1: number;
    value?: number;
    height?: number;
    depth?: number;
    data?: DonutData | unknown;
    children?: ArcData[];
    current?: ArcData;
}

interface HierachicalEl extends HierarchyRectangularNode<unknown> {
    current: ArcData | HierachicalEl;
    target: ArcData;
}

interface DonutEvents {
    dataHovered: (data: DonutData | null) => void,
    dataClicked: (data: DonutData | null) => void,
}

export default class Donut {
    svg!: d3.Selection<HTMLElement, unknown, null, undefined>;

    parent!: d3.Selection<SVGCircleElement, d3.HierarchyRectangularNode<unknown>, null, undefined>;

    root!: d3.HierarchyRectangularNode<unknown>;

    centreText!: d3.Selection<SVGImageElement, unknown, null, undefined>;

    events: DonutEvents | undefined;

    constructor () {
        this.clicked = this.clicked.bind(this);
    }

    initialise (el: HTMLElement,
        stats: DonutData | null,
        extents: (year: string, domain: string | undefined) => [ number, number, number],
        tooltipEl: HTMLElement,
        events?: DonutEvents,
        animate = true): void {
        if (!stats) return;

        this.events = events;
        let width = Math.min(window.innerWidth / 2.5, 500);
        let height = Math.min(window.innerWidth / 2.5, 500);
        if (window.innerWidth < 1000) {
            width = Math.min(window.innerWidth / 1.5, 500);
            height = Math.min(window.innerWidth / 1.5, 500);
        }
        const radius = width / 8;
        d3.select(el).selectAll('*').remove();

        this.svg = d3.select(el)
            .attr('height', height)
            .attr('width', width)
            .style('font', '11px Work Sans');

        const partition = (data: DonutData) => {
            const root = d3.hierarchy(data)
                .count()
                .sort((a, b) => (b?.value as number) - (a?.value as number));
            return d3.partition()
                .size([2 * Math.PI, root.height + 1])(root);
        };

        this.root = partition(stats);

        this.root.each((d) => { (d as HierachicalEl).current = d as HierachicalEl; });

        const arc = d3.arc<ArcData>()
            .startAngle((d) => d.x0)
            .endAngle((d) => d.x1)
            .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
            .padRadius(radius * 1.5)
            .innerRadius((d) => d.y0 * radius)
            .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius));

        const g = this.svg.append('g')
            .attr('transform', `translate(${width / 2},${width / 2})`);

        const path = g.append('g')
            .selectAll('path')
            .data(this.root.descendants().slice(1))
            .join('path')
            .attr('class', 'segment')
            .attr('stroke', '#fff')
            .attr('fill', (d: ArcData) => {
                const data = d.data as DonutData;
                if (!d) return '#444';
                let a = d;
                while (a.depth || 0 > 1) {
                    a = (a as HierachicalEl).parent as HierachicalEl;
                }
                const val = data.value;
                return data.colour ?? this.colour(val, data.indicator || data.subdomain || data.domain, extents) as string;
            })
            .attr('d', (d) => arc((d as HierachicalEl).current as ArcData));

        if (animate) {
            path.attr('opacity', 0);
            path.transition().delay((d, i) => d.depth * 100 + i * 10).duration(400).attr('opacity', 1);
        }

        path
            .style('cursor', 'pointer')
            .on('mouseenter', (e: MouseEvent, d: ArcData) => {
                d3.select(e.target as SVGElement).attr('stroke-width', 2).attr('stroke', '#333');
                d3.select(tooltipEl)
                    .style('left', `${e.offsetX}px`)
                    .style('top', `${e.offsetY}px`)
                    .style('opacity', 1);
                    events!.dataHovered(d.data as DonutData);
            })
            .on('mouseleave', () => {
                this.svg.selectAll('.segment').attr('stroke-width', 0);
                d3.select(tooltipEl)
                    .style('opacity', 0);
                events!.dataHovered(null);
            })
            .on('click', this.clicked);

        const labelImage = (d: DonutData) => {
            if (!d.indicator && !d.subdomain) {
                switch (d.domain) {
                case 'Health': return '/pe.png';
                case 'Skills and work': return '/employment.png';
                case 'Wealth': return '/economy.png';
                case 'Welfare': return '/welfare.svg';
                default: return '/education.svg';
                }
            }
            return null;
        };

        const labelSize = (d: DonutData) => {
            if (!d.indicator && !d.subdomain) {
                switch (d.domain) {
                case 'Health': return 12;
                case 'Skills and work': return 15;
                case 'Wealth': return 12;
                case 'Welfare': return 12;
                default: return 15;
                }
            }
            return 0;
        };
        this.parent = g.append('circle')
            .datum(this.root)
            .attr('r', radius)
            .attr('fill', 'none')
            .attr('pointer-events', 'all')
            .on('click', this.clicked);

        this.centreText = g.append('g')
            .attr('pointer-events', 'none')
            .attr('text-anchor', 'middle')
            .style('user-select', 'none')
            .append('image')
            .attr('opacity', 0)
            .attr('xlink:href', '/back.png')
            .attr('width', width / 9)
            .attr('x', -width / 18)
            .attr('y', -width / 18)
            .attr('height', width / 9);

        const label = g.append('g')
            .attr('pointer-events', 'none')
            .attr('image-anchor', 'middle')
            .style('user-select', 'none')
            .selectAll('image')
            .data(this.root.descendants().slice(1))
            .join('image')
            .attr('x', (d) => arc.centroid(d)[0] - width / (labelSize(d.data as DonutData) * 2))
            .attr('y', (d) => arc.centroid(d)[1] - width / (labelSize(d.data as DonutData) * 2))
            .attr('class', 'text')
            .attr('xlink:href', (d) => labelImage(d.data as DonutData))
            .attr('width', (d) => width / labelSize(d.data as DonutData))
            .attr('height', (d) => width / labelSize(d.data as DonutData))
            .attr('font-family', 'Work Sans');

        if (animate) {
            label.attr('opacity', 0);
            label.transition().delay((d, i) => d.depth * 100 + i * 10).duration(400).attr('opacity', 1);
        }
        this.highlightSelectedArcs(null);
    }

    highlightSelectedArcs (area: DonutData | null): void {
        const selectedDomain = area?.domain;
        const selectedSubdomain = area?.subdomain;
        // const selectedIndicator = this.healthData.selectedIndicator;

        if (!selectedDomain) {
            this.svg.selectAll('.segment').attr('opacity', 1);
            this.svg.selectAll('.text').attr('opacity', 1);
            this.centreText.attr('opacity', 0);
            return;
        }

        this.centreText.attr('opacity', 0.8);

        this.svg.selectAll('.segment').attr('opacity', (a: unknown) => {
            const b = (a as ArcData).data as DonutData;
            if ((b.domain === selectedDomain && (!b.subdomain || !selectedSubdomain || b.subdomain === selectedSubdomain)
                && (!b.indicator))) {
                return 1;
            }
            return 0.2;
        });
        this.svg.selectAll('.text').attr('opacity', (a: unknown) => {
            const b = (a as ArcData).data as DonutData;
            if ((b.domain === selectedDomain && !b.subdomain)
                        || (selectedSubdomain && b.subdomain === selectedSubdomain && !b.indicator)) {
                return 1;
            }
            return 0.2;
        });
    }

    clicked (event: MouseEvent, p: HierarchyRectangularNode<unknown>): void {
        let current: HierarchyRectangularNode<unknown> | null = null;

        this.parent.datum(p.parent || this.root);
        current = p;
        this.events!.dataClicked(current.data as DonutData);

        this.highlightSelectedArcs(current.data as DonutData);
    }

    colour (f: number, domain: string, extents: (data: string, domain2: string | undefined) => [ number, number, number]): number | string {
        return f ? d3.scaleLinear()
            .domain(extents('', domain))
            .interpolate(d3.interpolateHcl as never)
            .range(['#e84c19', '#fdeba1', '#66b42d'] as never)(f) : '#fdeba1';
    }
}
