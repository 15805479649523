















import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { DonutData } from '@/models/donutData';
import Donut from '../charts/donut';
import HealthService from '@/services/healthService';
import domains from '@/models/domains';

@Component
export default class DonutChart extends Vue {
    donut: Donut | null = null;

    healthService: HealthService = HealthService.get();

    animated = false;

    @Prop()
    stats!: DonutData;

    @Prop()
    extentsFunction!: (year: string, domain?: string) => [number, number, number];

    @Prop()
    year!: string;

    @Prop()
    animate!: boolean;

    mounted (): void {
        this.donut = new Donut();
        this.initialiseDonut();
    }

    hoveredDomain: DonutData | null = null;

    // get hoveredDomain () {
    //     return this.healthData?.hoveredDomain;
    // }

    get selectedDomainIcon (): string {
        switch (this.hoveredDomain?.name) {
        case 'Health infrastructure': return 'hospital';
        case 'Skills and work': return 'building';
        case 'Health': return 'heartbeat';
        case 'Wealth': return 'piggy-bank';
        case 'Welfare': return 'hands-helping';
        default: return domains.find((a) => a.name === this.hoveredDomain?.name)?.icon || 'times';
        }
    }

    get selectedDomainSecondaryIcon (): string {
        return domains.find((a) => a.name === this.hoveredDomain?.name)?.secondaryIcon || '';
    }

    get selectedDomainDescription (): string {
        return this.hoveredDomain?.name ?? '';
    }

    get selectedDomainValue (): string {
        return (Math.round((this.hoveredDomain?.value || 0) * 10) / 10).toLocaleString();
    }

    get selectedDomainColour (): string {
        if (!this.hoveredDomain) return '';
        const val = this.hoveredDomain.value || 0;
        return this.hoveredDomain.colour ?? this.healthService.getColour(val, this.hoveredDomain.name);
    }

    selectStat (data: DonutData): void {
        this.donut!.highlightSelectedArcs(data);
    }

    hasSelected = false;

    // @Watch('selectedDomain')
    // selectedDomainChanged () {
    //     if (this.selectedDomain) {
    //         this.hasSelected = true;
    //     }
    // }

    // get selectedDomain () {
    //     return this.healthData?.selectedDomain;
    // }

    @Watch('stats', { immediate: true })
    @Watch('extentsFunction')
    @Watch('year')
    @Watch('animate')
    initialiseDonut () : void{
        if (this.donut) {
            this.donut.initialise(
                this.$refs.donut as HTMLElement,
                this.stats,
                this.extentsFunction,
                this.$refs.tooltip as HTMLElement,
                {
                    dataHovered: (data: DonutData | null) => {
                        this.hoveredDomain = data;
                    },
                    dataClicked: (data: DonutData | null) => {
                        this.hasSelected = true;
                        this.$emit('selected', data?.name);
                    },
                },
                this.animate && !this.animated,
            );
            this.animated = true;
        }
    }
}
