/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from 'd3';

export default class Postcodes {
    private static postcodes: Postcodes;

    postcodeMap: Array<{ postcode: string; areaCode: string; areaName: string }> = [];

    static async get (): Promise<Postcodes> {
        if (!this.postcodes) {
            this.postcodes = new Postcodes();
            await this.postcodes.initialise();
        }
        return this.postcodes;
    }

    async initialise (): Promise<void> {
        await d3.csv('/postcodes.csv').then((response) => {
            this.postcodeMap = response as unknown as Array<{ postcode: string; areaCode: string; areaName: string }>;
        });
    }

    getAreaCodeFromPostcode (postcode: string): any[] {
        return this.removeDuplicates(this.postcodeMap
            .filter((a) => a.postcode.toLowerCase().indexOf(postcode.split(' ')[0].toLowerCase()) > -1 || a.areaName.toLowerCase().indexOf(postcode.toLowerCase()) > -1)
            .sort((a, b) => (a.postcode.indexOf(postcode) > b.postcode.indexOf(postcode) ? 1 : -1)), 'areaCode');
    }

    removeDuplicates (arr: Array<any>, prop: string): any[] {
        return arr.filter((obj, pos, a) => a.map((mapObj: any) => mapObj[prop]).indexOf(obj[prop]) === pos);
    }

    getPostCodeFromAreaCode (areaCode: string): { postcode: string; areaCode: string; areaName: string; }[] {
        return this.postcodeMap.filter((a) => a.areaCode.toLowerCase() === areaCode.toLowerCase());
    }
}
